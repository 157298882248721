<template>
    <div class="pembungkus">
        <img src="@/assets/element1.png" class="gambar" v-if="img === 1" alt="">
        <img src="@/assets/element2.png" class="gambar" v-else-if="img === 2" alt="">
        <img src="@/assets/element3.png" class="gambar" v-else-if="img === 3" alt="">
    </div>
</template>

<script>
export default {
    props:["img"]
}
</script>

<style lang="scss" scoped>
.pembungkus {
    margin-top: 0%;
    height: 200px;
    overflow: hidden;
}
.gambar {
    position: relative;
    left: 25%;
    width: 400px;
    z-index: 1;
}
</style>