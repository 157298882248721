import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "bootstrap/scss/bootstrap.scss";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCircleInfo,
  faXmark,
  faHatWizard,
  faEye,
  faEyeSlash,
  faCamera,
  faLocationDot,
  faBan,
  faCircleCheck,
  faCircleXmark,
  faCalendarDays,
  faArrowLeft,
  faChevronRight,
  faTimesCircle,
  faSync,
  faSearchPlus,
  faFilePdf,
  faFile,
  faDownload
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faDownload,
  faFile,
  faFilePdf,
  faCircleInfo,
  faXmark,
  faHatWizard,
  faEye,
  faEyeSlash,
  faCamera,
  faLocationDot,
  faBan,
  faCircleCheck,
  faCircleXmark,
  faCalendarDays,
  faArrowLeft,
  faTimesCircle,
  faSync,
  faChevronRight,
  faSearchPlus
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import "./scss/main.scss";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import vuetify from "@/plugins/vuetify"; // path to vuetify export

import fcm from "./fcm.js";

import Notifications from "vue-notification";
Vue.use(Notifications);

import helpers from "./helper";
const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
    Vue.prototype.$fcm = fcm;
  },
};
Vue.use(plugin);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
