<template>
    <notifications position="top center" :duration="6000" style="width: 100%; max-width: 425px; left: inherit;">
        <template slot="body" slot-scope="{ close }">
            <div class="bg-white rounded-3 p-3 d-flex shadow border-notif">
                <div>
                    <font-awesome-icon icon="fa-solid fa-circle-info" style="height: 25px; color: #42A5F5" />
                </div>
                <div class="flex-column flex-fill text-start ms-3">
                    <div class="title">{{ title }}</div>
                    <div class="body" v-html="body" style="white-space:pre-line"></div>
                    <div class="border-top mt-2">
                        <button type="button" @click="action" class="text-white btn btn-primary btn-sm w-100 mt-2"
                            style="font-size: 9pt;">Lihat</button>
                    </div>
                </div>
                <div>
                    <font-awesome-icon icon="fa-solid fa-xmark" @click="close"
                        style="height: 20px; color: lightslategrey" />
                </div>
            </div>
        </template>
    </notifications>
</template>

<script>
export default {
    computed: {
        title() {
            return this.$store.getters.notificationTitle
        },
        body() {
            return this.$store.getters.notificationBody
        },
        action() {
            return this.$store.getters.notificationAction
        }
    }
}
</script>

<style lang="scss" scoped>
.border-notif {
    border: 1.5px solid #42A5F5;
    border-left: 6px solid #42A5F5 !important;
}
.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
}

.body {
    font-size: 13px;
}
</style>