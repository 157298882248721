<template>
  <div class="row mt-3" style="margin-top: 15vh" v-if="!isLoadingGET">
    <div class="col-12 text-center">
      <h1 class="login-karyawan-title color-primary" @click="test" style="margin-bottom: 1rem;line-height: 1.5;">
        PT. HADEKA <br>SWASEMBADA JAYA
        <br> {{ token }}
      </h1>
    </div>
    <!-- <div class="col-3 text-end">
            <img src="@/assets/Mail.png" class="mail position-relative" alt="">
            <span class="position-absolute translate-middle badge rounded-pill bg-danger">
                1</span>
        </div> -->
    <div class="col-12">
      <div class="row">
        <div class="col-4 offset-1">

          <v-img :aspect-ratio="1 / 1" :src="user_img ? user_img : require(`@/assets/new/profile (1).png`)"
            class="rounded-circle"></v-img>
          <!-- <img :src="user_img ? user_img : require(`@/assets/new/profile (1).png`)" class="rounded-circle user" /> -->


        </div>
        <div class="col-7 px-0">
          <div class="d-flex flex-column ms-1 justify-content-center align-items-start text-start">
            <div class="">
              <p class="nama-karyawan">{{ nama }}</p>
            </div>
            <div class="">
              <p class="jabatan-karyawan">{{ jabatan }}</p>
            </div>
            <div class="mb-2">
              <p style="font-size: 10pt" class="mb-0">{{ lokasi }}</p>
            </div>
            <div class="row w-100">
              <div class="col-6 pe-0"><button @click="switchaccount" type="button" style="font-size:10pt"
                  class="text-white btn btn-primary btn-sm w-100">Ganti
                  Akun</button></div>
              <div class="col-5 ps-2"><button type="button" style="font-size:10pt"
                  class="btn btn-danger btn-sm text-white w-100" @click="logout">Logout</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-11 mt-3 mx-auto">
      <div class="alert alert-birulangit text-start" style="border-radius:20px" role="alert">
        <h6 class="alert-heading fw-bold">Info Pengguna</h6>
        <ul>
          <li>
            <p style="font-size:10pt" class="mb-0">Pastikan ponsel anda terhubung internet dengan baik saat menggunakan
              aplikasi ini.</p>
          </li>
          <li>
            <p style="font-size:10pt" class="mb-0">Silahkan berikan izin kamera dan GPS saat melakukan absensi</p>
          </li>
          <li>
            <p style="font-size:10pt" class="mb-0">Pastikan berikan izin Notification untuk dapat mendapatkan notifikasi</p>
          </li>
          <li v-if="is_browser_support === 'true'">
            <p style="font-size:10pt; font-weight:700" class="mb-0">Maaf device anda tidak support dan tidak bisa menerima notifikasi</p>
          </li>
        </ul>

      </div>
    </div>
    <div class="col-12 ">
      <div class="row">
        <template v-for="(item, index) in menu">
          <div class="col-6" v-if="index % 2 == 0" :key="`menu_kiri${index}`">
            <div class="card hover h-100 menu-kiri border-radius background-color-secondary"
              @click="$router.push(`${item.url}`)">
              <div class="card-body">
                <img :src="`${item.img}`" class="icon-menu" alt="" />
                <p class="mb-0 mt-2 font-size-menu">{{ item.title }}</p>
              </div>
            </div>
          </div>
          <div class="col-6" v-else-if="index % 2 == 1" :key="`menu_kanan${index}`">
            <div class="card hover h-100 menu-kanan border-radius background-color-third"
              @click="$router.push(`${item.url}`)">
              <div class="card-body">
                <img :src="`${item.img}`" class="icon-menu" alt="" />
                <p class="mb-0 mt-2 font-size-menu">{{ item.title }}</p>
              </div>
            </div>
          </div>
        </template>

        <div class="col-12 fw-bold" style="margin-top: 1rem">
          {{ $store.getters.versi }}
          <!-- <button class="btn btn-primary text-white" type="button" @click="logout">
            Logout
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Dashboards />
  </div>
</template>

<script>
import Dashboards from "../components/Skeleton/Dashboard.vue";
export default {
  components: {
    Dashboards,
  },
  data() {
    return {
      user_img: "",
      nama: "",
      lokasi: "",
      jabatan: "",
      tingkat_karyawan: "",
      role: ['ACC Ijin & Lembur'],
      // token: localStorage.getItem('tfcm'),
      is_browser_support: localStorage.getItem('is_safari'),
      token: '',
      menu: [
        {
          url: '/check-in',
          img: require('@/assets/new/offices.png'),
          title: 'Absensi Check In'
        },
        {
          url: '/check-out',
          img: require('@/assets/new/offices2.png'),
          title: 'Absensi Check Out'
        },
        {
          url: '/kunjungan-cabang',
          img: require('@/assets/new/k_cabang_checkin.png'),
          title: 'K. Cabang Check In'
        },
        {
          url: '/kunjungan-cabang-checkout',
          img: require('@/assets/new/k_cabang_checkout.png'),
          title: 'K. Cabang Check Out'
        },
        {
          url: '/surat-peringatan',
          img: require('@/assets/new/warning.png'),
          title: 'Surat Peringatan'
        },
        {
          url: '/ijin',
          img: require('@/assets/new/export.png'),
          title: 'Pengajuan Ijin & Lembur'
        },
        {
          url: '/payroll',
          img: require('@/assets/new/salary.png'),
          title: 'Payroll'
        },
        {
          url: '/angsuran',
          img: require('@/assets/new/wallet.png'),
          title: 'Angsuran Pinjaman'
        },
        {
          url: '/history-absensi',
          img: require('@/assets/new/time.png'),
          title: 'Histori Absensi'
        },
        {
          url: '/history-ijin',
          img: require('@/assets/new/medical-record.png'),
          title: 'History P. Ijin & Lembur'
        },
        {
          url: '/profile',
          img: require('@/assets/new/programmer.png'),
          title: 'Profile'
        },
        {
          url: '/history-kunjungan-cabang',
          img: require('@/assets/new/work-history.png'),
          title: 'Histori K. Cabang'
        },
      ]
    };
  },
  methods: {
    test() {
      // this.$store.getters.modalLoading.show();
      // const myTimeout = setTimeout(() => {
      //   const temp = this.$store.getters.modalLoadingContent;
      //   temp.status = 2;
      //   temp.caption = `<p class="mb-0" style="color:red;font-size:14pt">Berhasil Absen</p>`;
      //   temp.title = `<p class="mb-0" style="font-size:20pt">Sukses..</p>`;
      //   this.$store.commit("setModalLoadingContent", temp);
      //   const myTimeout2 = setTimeout(() => {
      //     this.$store.getters.modalLoading.hide();
      //     clearTimeout(myTimeout2);
      //   }, 3000);

      //   clearTimeout(myTimeout);
      // }, 3000);
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    checkRole() {
      // jika ada acc ijin/lembur
      // for (const item of this.role) {
      if (this.tingkat_karyawan !== 'Karyawan') {
        this.menu.splice(4, 0, {
          url: '/acc-ijin-lembur',
          img: require('@/assets/new/stamp.png'),
          title: 'ACC Ijin & Lembur'
        })
        this.menu = this.array_move(this.menu, 5, 6)
        this.menu = this.array_move(this.menu, 11, 12)
      }
      // }
    },
    async switchaccount() {
      this.$helpers.start_loading(this, 0,
        `<p class="mb-0 text-start" style="line-height:1.5">Memproses Ganti Akun</p>`,
        `<p class="mb-0" style="font-size:20pt;">Loading...</p>`)

      const res_karyawan = await this.$store.dispatch("callAPI", {
        url: "/api/method/hadeka_hr.api.karyawan.get_list_karyawan"
      });

      if (res_karyawan.data.message.data.length > 1) {
        this.$store.commit('setMK', true)
        this.$store.commit('setMKList', res_karyawan.data.message.data)
        localStorage.setItem('SLM', 1)
        localStorage.setItem('mkList', JSON.stringify(res_karyawan.data.message.data))
        window.location.href = '/login-karyawan'
        // this.$router.push("/login-karyawan");
      } else if (res_karyawan.data.message.data.length === 1) {
        this.$helpers.finish_loading(this, 2,
          `<p class="mb-0 text-start" style="color:red;font-size:12pt">Akun anda hanya ada 1 saja.</p>`,
          `<p class="mb-0" style="font-size:16pt">Ganti Akun Gagal...</p>`, 2000)
      }
    },
    async logout() {
      this.$helpers.Logout(this.$store);
    },
    async setupUser() {
      const karyawan = localStorage.getItem("mk")
      if (!karyawan)
        this.logout()


      const res = await this.$store.dispatch("callAPI", {
        url: "/api/method/hadeka_hr.api.karyawan.get_detail_karyawan",
        payload: {
          karyawan: localStorage.getItem("mk"),
        },
      });
      if (res.data.message.code === 200) {
        // Ini klo pake b64
        // this.user_img = res.data.message.data.foto ? await this.$helpers.getBase64FromUrl(
        //   this.$store.getters.baseImage + res.data.message.data.foto
        // ) : '';
        this.user_img = res.data.message.data.foto ?
          this.$store.getters.baseImage + res.data.message.data.foto : '';
        this.nama = res.data.message.data.nama_panggilan;
        this.lokasi = res.data.message.data.cabang;
        this.jabatan = res.data.message.data.jabatan;
        this.tingkat_karyawan = res.data.message.data.tingkat_karyawan;
        localStorage.setItem('rac', this.tingkat_karyawan)

        this.$store.commit('setUser', res.data.message.data.user)
        this.$store.commit('setTokenFCMList', res.data.message.data.token_firebase)
      }
    },
  },
  computed: {
    isLoadingGET() {
      return this.$store.getters.loadingGET;
    },
  },
  async created() {
    this.$store.commit("setLoadingGET", true);
    await this.setupUser();
    this.$store.commit("setLoadingGET", false);
    this.$helpers.resetLocalStorage();

    this.checkRole();
  },
};
</script>

<style lang="scss" scoped>
.mail {
  width: 32px;
  margin-top: 0.3rem;
}

.user {
  // width: 128px;
  max-width: 100%;
  // aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
}

.menu-kiri {
  width: 120px;
  margin-left: auto;
}

.menu-kanan {
  width: 120px;
  margin-right: auto;
}

.icon-menu {
  // max-width: 100%;
  // width: 64px;
  max-width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.border-radius {
  border-radius: 15px;
}

.font-size-menu {
  font-size: 10pt;
  color: white;
  font-weight: 700;
}
</style>