import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Dashboard
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/acc-ijin-lembur',
    component: () => import('../views/Acc_Ijin.vue')
  },
  {
    path: '/payroll',
    component: () => import('../views/Payroll.vue')
  },
  {
    path: '/login-karyawan',
    component: () => import('../views/LoginKaryawan.vue')
  },
  {
    path: '/profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/check-in',
    component: () => import('../views/Checkin.vue')
  },
  {
    path: '/history-absensi',
    component: () => import('../views/HistoryAbsensi.vue')
  },
  {
    path: '/history-ijin',
    component: () => import('../views/HistoryIjin.vue')
  },
  {
    path: '/angsuran',
    component: () => import('../views/Angsuran.vue')
  },
  {
    path: '/surat-peringatan',
    component: () => import('../views/SP.vue')
  },
  {
    path: '/history-kunjungan-cabang',
    component: () => import('../views/HistoryKunjunganCabang.vue')
  },
  {
    path: '/kunjungan-cabang',
    component: () => import('../views/KunjunganCabang.vue')
  },
  {
    path: '/kunjungan-cabang-checkout',
    component: () => import('../views/CheckoutCabang.vue')
  },
  {
    path: '/check-out',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/ijin',
    component: () => import('../views/Ijin.vue')
  },
  {
    path: '/pdf',
    component: () => import('../views/Pdf.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
