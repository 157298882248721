const data = {
    state: () => ({
        // multi karyawan
        mk: false,
        mk_list: [],
        count: 0,
        user: null
    }),
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setMKList(state, payload) {
            state.mk_list = payload
        },
        setMK(state, payload) {
            state.mk = payload
        },
        increment(state) {
            // `state` is the local module state
            state.count++
        }
    },
    getters: {
        mk: (state) => { return state.mk },
        mk_list: state => state.mk_list,
        user: (state) => {
            return state.user
        },
        doubleCount(state) {
            return state.count * 2
        }
    },
    actions: {
        incrementIfOddOnRootSum({
            state,
            commit,
            rootState
        }) {
            if ((state.count + rootState.count) % 2 === 1) {
                commit('increment')
            }
        }
    }
}
export default data