const domain = "Hadekasj.com";
import CryptoJS from "crypto-js";
import Vue from "vue";
import router from "./router/index";
import axios from "axios";
const helper = {
  formatRupiah(money, prefix = false) {
    if (!prefix)
      return new Intl.NumberFormat("id-ID", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(money);
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(money);
  },
  start_loading(me, status, caption, title) {
    me.$store.getters.modalLoading.show();
    // const temp = {
    //     status: 0,
    //     caption: `<p class="mb-0 text-start" style="line-height:1.5">Memverifikasi</p>`,
    //     title: `<p class="mb-0" style="font-size:20pt;">Loading...</p>`
    // }
    const temp = {
      status: status,
      caption: caption,
      title: title,
    };

    me.$store.commit("setModalLoadingContent", temp);
  },
  disable_loading(me) {
    const myTimeout2 = setInterval(() => {
      me.$store.getters.modalLoading.hide();
      clearInterval(myTimeout2);
    }, 100);
  },
  finish_loading(me, status, caption, title, time) {
    const temp = me.$store.getters.modalLoadingContent;
    temp.status = status;
    temp.caption = caption;
    temp.title = title;
    me.$store.commit("setModalLoadingContent", temp);
    const myTimeout2 = setTimeout(() => {
      me.$store.getters.modalLoading.hide();
      clearTimeout(myTimeout2);
    }, time);
  },
  loading_hide(me) {
    me.$store.getters.modalLoading.hide();
    let c = 0;
    const myTimeout2 = setInterval(() => {
      c += 10;
      if (c == 250) clearTimeout(myTimeout2);
      me.$store.getters.modalLoading.hide();
    }, 10);
  },
  resetLocalStorage() {
    localStorage.removeItem("SLM");
  },
  SUP(u, p) {
    const e = CryptoJS.AES.encrypt(`${u};${p}`, domain).toString();
    localStorage.setItem("up", e);
  },
  GUP() {
    const c = localStorage.getItem("up");
    if (c) {
      const bytes = CryptoJS.AES.decrypt(c, domain);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      const sp = decryptedData.split(";");
      return {
        u: sp[0],
        p: sp[1],
      };
    }
    return 0;
  },
  async getBase64FromUrl(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  },
  async Logout(me) {
    let name_token = null;
    const token_now = localStorage.getItem("tfcm");

    if (me.getters.tokenFCMList) {
      for (const item of me.getters.tokenFCMList) {
        if (token_now === item.token) {
          name_token = item.name;
          break;
        }
      }
    }


    if (name_token) {
      const res = await me.dispatch("callAPI", {
        url: "/api/method/hadeka_hr.api.general.deleteDoc",
        payload: {
          doctype: "Firebase Token",
          name: name_token,
        },
      });
    }

    const domain = me.state.api.domain;
    await axios.get(`${domain}/api/method/logout`);

    localStorage.clear();

    // clear all cookies
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    window.location.replace("/login");
    router.replace("/login");
  },
};

export default helper;
