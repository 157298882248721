<template>
  <div id="app" class="app-margin-fix overflow-hidden">
    <Back v-show="useNavbar" />
    <Header :class="classHeader" />
    <Loading />
    <Popup />
    <Notification />
    <div class="container" ref="container">
      <router-view />
    </div>
    <div ref="auto_fit_height"></div>
    <Footer ref="footer" id="footer" :class="classFooter" :img="propsFooter" />
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Loading from "./components/Loading.vue";
import Popup from "./components/Popup.vue";
import Back from "./components/BackButton.vue";
import { getMessaging, onMessage, deleteToken } from "firebase/messaging";
import Notification from "./components/v1.3.0/Notification.vue";
import moment from "moment";

export default {
  components: {
    Header,
    Footer,
    Loading,
    Back,
    Popup,
    Notification,
  },
  computed: {
    useNavbar() {
      return this.$store.getters.useNavbar.status;
    },
  },
  mounted() {
    this.observeHeight();

    window.addEventListener('get_token_fcm', (event) => {
      if (this.$store.getters.tokenFCMList)
        this.setupFCM();
    });
  },
  created() {
    // console.log(this.$fcm)
  },
  data() {
    return {
      classHeader: "",
      classFooter: "",
      propsFooter: 1,
    };
  },
  watch: {
    "$store.getters.tokenFCMList"() {
      this.setupFCM();
    },
  },
  methods: {
    async setupFCM() {
      onMessage(this.$fcm, (payload) => {
        console.log(payload);
        // console.log('Message received. ', payload);
        this.$store.commit("setNotification", {
          title: payload.data.title,
          body: payload.data.body,
          action: () => {
            this.$router.push("/acc-ijin-lembur");
          },
        });
        this.$notify({ type: "success" });
        // unutk hapus token
        // deleteToken(this.$fcm).then((res) => {
        //   console.log(res)
        // })
      });

      const tokenFCM = localStorage.getItem("tfcm");
      const tokenFCMList = this.$store.getters.tokenFCMList;
      let name_token = '';

      for (const item of tokenFCMList) {
        if (item.token === tokenFCM) {
          name_token = item.name
          break;
        }
      }

      if (tokenFCM) {
        let payload = {
          doctype: "Firebase Token",
          user: this.$store.getters.user,
          token: tokenFCM,
          last_update: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        if (name_token)
          payload.name = name_token

        const res = await this.$store.dispatch("callAPI", {
          url: "/api/method/hadeka_hr.api.general.createOrUpdateDoc",
          payload: payload,
        });
      }

    },
    observeHeight() {
      const me = this;
      const resizeObserver = new ResizeObserver(function () {
        me.auto_height();
      });

      resizeObserver.observe(this.$refs.container);
    },
    auto_height() {
      const calc_height =
        this.$vuetify.breakpoint.height -
        this.$refs.container.offsetHeight -
        this.$refs.footer.$el.offsetHeight;
      // console.log(calc_height)
      // console.log(this.$vuetify.breakpoint.height, this.$refs.container.offsetHeight, this.$refs.footer.$el.offsetHeight)
      if (calc_height > 0) {
        this.$refs.auto_fit_height.style.height = `${calc_height}px`;
      } else {
        this.$refs.auto_fit_height.style.height = `0px`;
      }
    },
  },
  updated() {
    // this.auto_height();
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f7f7ff;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
