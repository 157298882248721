const data = {
  state: () => ({
    loadingGET: true,
    loadingPOST: false,
    modalLoading: null,
    modalLoadingContent: {
      status: 0,
      title: "Mohon Tunggu..",
      caption: "Do Something",
    },
    useNavbar: {
      text: "Check In",
      class: "",
      status: false,
    },
    backWindow: false,
    Popup: null,
    PopupHiddenCB: () => {},
    PopupContent: {
      status: 0,
      title: "Mohon Tunggu..",
      caption: "Do Something",
    },
    notificationTitle: "",
    notificationBody: "",
    notificationAction: null,
    tokenFCMList: null,
  }),
  mutations: {
    setPopupHiddenCB(state, payload) {
      state.PopupHiddenCB = payload;
    },
    setPopup(state, payload) {
      state.Popup = payload;
    },
    setPopupContent(state, payload) {
      state.PopupContent = payload;
    },
    setBackWindow(state, payload) {
      state.backWindow = true;
    },
    setLoadingGET(state, payload) {
      state.loadingGET = payload;
    },
    setLoadingPOST(state, payload) {
      state.loadingPOST = payload;
    },
    setModalLoading(state, payload) {
      state.modalLoading = payload;
    },
    setModalLoadingContent(state, payload) {
      state.modalLoadingContent = payload;
    },
    setNotification(state, payload) {
      state.notificationTitle = payload.title;
      state.notificationBody = payload.body;
      state.notificationAction = payload.action;
    },
    setTokenFCMList(state, payload) {
      state.tokenFCMList = payload;
    },
    toogleNavbar(state, payload) {
      state.useNavbar = {
        text: payload.text,
        class: payload.class ? payload.class : "",
        status: payload.status,
      };
    },
  },
  getters: {
    loadingGET: (state) => state.loadingGET,
    loadingPOST: (state) => state.loadingPOST,
    modalLoading: (state) => state.modalLoading,
    modalLoadingContent: (state) => state.modalLoadingContent,
    useNavbar: (state) => state.useNavbar,
    backWindow: (state) => state.backWindow,
    usePopup: (state) => state.usePopup,
    Popup: (state) => state.Popup,
    PopupContent: (state) => state.PopupContent,
    PopupHiddenCB: (state) => state.PopupHiddenCB,
    notificationTitle: (state) => state.notificationTitle,
    notificationBody: (state) => state.notificationBody,
    notificationAction: (state) => state.notificationAction,
    tokenFCMList: (state) => state.tokenFCMList,
  },
  actions: {
    // setNotification({ state, commit, rootState }, payload) {
    //   console.log(payload)
    // },
  },
};
export default data;
