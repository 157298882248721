import Vue from 'vue'
import Vuex from 'vuex'
import login from './login'
import dashboard from './dashboard'
import api from './api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    dashboard,
    api
  }
})
