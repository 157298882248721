import axios from 'axios';
import Vue from 'vue';
const data = {
	state: () => ({
		domain: 'api/req.php?url=',
		// baseImage: process.env.NODE_ENV === 'development' ? 'https://dev-hr.hadekasj.com/api/req.php?url=' : 'api/req.php?url=',
		baseImage: 'https://admin.hadekasj.com' ,
		baseImage2: '/api/get_image.php?path=',
		// versi: 'v1.2.10'
		versi: 'v1.3.3'
	}),
	mutations: {

	},
	getters: {
		baseImage: state => state.baseImage,
		baseImage2: state => state.baseImage2,
		versi: state => state.versi
	},
	actions: {
		async callAPI({
			state,
			commit,
			getters,
			rootState,
		}, payload) {
			let err, res = null
			if (!localStorage.getItem('mk') && localStorage.getItem('up')) {
				this._vm.$helpers.Logout(this)
				return
			}
			try {
				res = await axios.post(`${state.domain}${payload.url}`, payload.payload, payload.headers ? payload.headers : {})
			} catch (error) {
				err = error
			}

			if (res) {
				if (res.data.exc_type === 'PermissionError') {
					const up = this._vm.$helpers.GUP()
					if (up !== 0) {
						const payld = {
							usr: up.u,
							pwd: up.p,
						}
						const res_relogin = await axios.post(`${state.domain}/api/method/hadeka_hr.api.general.login`, payld)
						if (res_relogin.data.message.code === 200) {
							window.location.reload();
						} else {
							this._vm.$helpers.Logout(this)
						}
					} else {
						this._vm.$helpers.Logout(this)
					}
				} else return res
			} else {
				if (err.response.status === 403) {
					const up = this._vm.$helpers.GUP()
					if (up !== 0) {
						const payld = {
							usr: up.u,
							pwd: up.p,
						}
						const res_relogin = await axios.post(`${state.domain}/api/method/hadeka_hr.api.general.login`, payld)
						if (res_relogin.data.message.code === 200) {
							window.location.reload();
						} else {
							this._vm.$helpers.Logout(this)
						}
					} else {
						this._vm.$helpers.Logout(this)
					}
				}
			}
		},
		async testAPI({
			state,
			commit,
			rootState,
		}, payload) {
			let err, res = null
			try {
				res = await axios.post(`/api/logout.php`)
			} catch (error) {
				err = error
			}

		}
	}
}
export default data