// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

import { onBackgroundMessage } from "firebase/messaging/sw";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// ini akunku
const firebaseConfig = {
  apiKey: "AIzaSyDfV2x2XOhJcY8fTt21bLdVgNarc05M7gI",
  authDomain: "hadeka-hr.firebaseapp.com",
  projectId: "hadeka-hr",
  storageBucket: "hadeka-hr.appspot.com",
  messagingSenderId: "954050485631",
  appId: "1:954050485631:web:923b31b4ac367ec4b54e4a",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAtt3l8eRLTd06nvY4zmcZL3KWyv4Tsb14",
//   authDomain: "petnpop-c142c.firebaseapp.com",
//   projectId: "petnpop-c142c",
//   storageBucket: "petnpop-c142c.appspot.com",
//   messagingSenderId: "652719634220",
//   appId: "1:652719634220:web:f3d7b9f75ae99713bbde89",
//   measurementId: "G-TD343JC9NL"
// }

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

console.log("Requesting permission...");
const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"] || safari.pushNotification);
const isSafari2 = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
localStorage.setItem('is_safari', isSafari2)
console.log(isSafari);
console.log(isSafari2);
if (isSafari2 === false) {
  try {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        getToken(messaging, {
          // vapidKey:
          //   "BFd9JKok0Jzrd4NhGYyGAxTuP6qI-yiX2B5VW3G0cjcT7oy42Pw-P-5gOBNKug6R03upyaH3tiGbr6euovdapTs",
          vapidKey:
            "BFd9JKok0Jzrd4NhGYyGAxTuP6qI-yiX2B5VW3G0cjcT7oy42Pw-P-5gOBNKug6R03upyaH3tiGbr6euovdapTs",
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log(currentToken);
              localStorage.setItem("tfcm", currentToken);
              window.dispatchEvent(
                new CustomEvent("get_token_fcm", {
                  detail: {
                    storage: "test",
                  },
                })
              );
            } else {
              // Show permission request UI
              console.log(
                "No registration token available. Request permission to generate one."
              );
              // ...
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          });
      } else {
        console.log("Permission Denied");
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export default messaging;

// import { initializeApp } from 'firebase/app'
// // import 'firebase/firebase-messaging'

// const firebaseConfig = {
//     apiKey: "AIzaSyDfV2x2XOhJcY8fTt21bLdVgNarc05M7gI",
//     authDomain: "hadeka-hr.firebaseapp.com",
//     projectId: "hadeka-hr",
//     storageBucket: "hadeka-hr.appspot.com",
//     messagingSenderId: "954050485631",
//     appId: "1:954050485631:web:923b31b4ac367ec4b54e4a"
//   };

// const app = initializeApp(firebaseConfig)

// export default app.messaging()
