<template>
  <div class="v-application" data-app>
    <v-app-bar elevation="2" class="fixed app-margin-fix padding-fix" elevate-on-scroll color="primary">
      <v-app-bar-nav-icon v-if="!$store.getters.backWindow" @click="$router.back()">
        <font-awesome-icon icon="fa-solid fa-arrow-left" style="height:24px;color:white" />
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-else @click="back">
        <font-awesome-icon icon="fa-solid fa-arrow-left" style="height:24px;color:white" />
      </v-app-bar-nav-icon>
      <v-toolbar-title class="w-100 fw-bold"
        style="margin-right:3rem; color:white; font-family: 'Poppins', sans-serif !important">
        {{$store.getters.useNavbar.text}}</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    methods: {
      back() {
        location.href = "/";
      }
    }
  }
</script>

<style lang="scss">
  .padding-fix .v-toolbar__content {
    padding: 4px;
  }
</style>

<style lang="scss" scoped>
  .fixed {
    position: fixed;
    top: 0;
    z-index: 5;
  }
</style>