<template>
  <div
    class="modal fade"
    ref="popup"
    id="popup"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    style="z-index: 1100;"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="d-flex flex-column p-fix">
          <div class="text-start">
            <h3 v-html="title"></h3>
          </div>
          <div class="flex-row d-flex mt-3">
            <div
              :class="`align-self-center ${status === 3 ? 'flex-grow-1' : ''}`"
            >
              <div
                v-if="status === 0"
                class="spinner-border text-primary"
                role="status"
                style="width: 3rem; height: 3rem"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <div v-else-if="status === 1">
                <font-awesome-icon
                  icon="fa-solid fa-circle-check"
                  style="height: 50px; color: green"
                />
              </div>
              <div v-else-if="status === 2">
                <font-awesome-icon
                  icon="fa-solid fa-circle-xmark"
                  style="height: 50px; color: red"
                />
              </div>
              <div class="progress" v-else-if="status === 3">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="`width: ${percent}%`"
                  :aria-valuenow="percent"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ percent }}%
                </div>
              </div>
            </div>
            <div
              class="ms-4 align-self-center h6 caption"
              v-if="caption"
              v-html="caption"
            ></div>
          </div>
          <div class="mt-3" v-if="status != 3 && status != 0">
            <button
              type="button"
              @click="closeModal"
              class="btn btn-primary text-white w-100"
              data-bs-dismiss="popup"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  data() {
    return {
      myModal: null,
    };
  },
  computed: {
    caption() {
      return this.$store.getters.PopupContent.caption;
    },
    percent() {
      if (this.$store.getters.PopupContent.percent) {
        this.myModal._config.backdrop = false;
        this.myModal._config.keyboard = false;
        return this.$store.getters.PopupContent.percent;
      } else {
        this.myModal._config.backdrop = true;
        this.myModal._config.keyboard = true;
        return 0;
      }
    },
    title() {
      return this.$store.getters.PopupContent.title;
    },
    status() {
      return this.$store.getters.PopupContent.status;
    },
  },
  mounted() {
    this.myModal = new Modal(this.$refs.popup);
    this.$store.commit("setPopup", this.myModal);

    const me = this;
    this.$refs.popup.addEventListener("hidden.bs.modal", function (event) {
      me.$store.getters.PopupHiddenCB();
    });
  },
  methods: {
    closeModal() {
      this.$store.getters.Popup.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.caption {
  color: #6d6d6d8c;
}

.p-fix {
  padding: 2rem;
}
</style>
