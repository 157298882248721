<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      ref="loading"
      id="loading"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      style="z-index: 2000"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="d-flex flex-column p-fix">
            <div class="text-start">
              <h3 v-html="title"></h3>
            </div>
            <div class="flex-row d-flex mt-3">
              <div>
                <div
                  v-if="status === 0"
                  class="spinner-border text-primary"
                  role="status"
                  style="width: 3rem; height: 3rem"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div v-else-if="status === 1">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    style="height: 50px; color: green"
                  />
                </div>
                <div v-else-if="status === 2">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-xmark"
                    style="height: 50px; color: red"
                  />
                </div>
              </div>
              <div
                class="ms-4 align-self-center h6 caption"
                v-html="caption"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  data() {
    return {};
  },
  computed: {
    caption() {
      return this.$store.getters.modalLoadingContent.caption;
    },
    title() {
      return this.$store.getters.modalLoadingContent.title;
    },
    status() {
      return this.$store.getters.modalLoadingContent.status;
    },
  },
  mounted() {
    this.$store.commit("setModalLoading", new Modal(this.$refs.loading));
    // console.log(new Modal(document.getElementById('loading')).show())
  },
};
</script>

<style lang="scss" scoped>
.caption {
  color: #6d6d6d8c;
}
.p-fix {
  padding: 2rem;
}
</style>
